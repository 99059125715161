<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h1 class="display-2 text-center">{{ $t('Common.why_takein') }}</h1>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h3 class="text-center">
          <router-link to="/signup" class="success--text">{{
            $t('Common.signup_now')
          }}</router-link>
        </h3>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col cols="12" md="6">
        <v-card height="540" class="pt-6">
          <v-card-text>
            <iframe
              width="100%"
              height="430"
              src="https://www.youtube-nocookie.com/embed/QZoC4pFfYkM?controls=0"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card height="540">
          <v-card-title>
            <h1
              class="font-weight-medium"
              v-html="$t('Common.increase_profit')"
            ></h1>
          </v-card-title>

          <v-card-text>
            <h3 class="font-weight-light">
              <span v-html="$t('Common.increase_profit_description')"></span>

              <ul class="mt-5">
                <li>{{ $t('Common.increase_profit_description_item0') }}</li>
                <li>{{ $t('Common.increase_profit_description_item1') }}</li>
                <li>{{ $t('Common.increase_profit_description_item3') }}</li>
              </ul>
            </h3>
            <v-row align="center" justify="center">
              <v-card width="70%">
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          colspan="3"
                          class="theader text-center success pt-2"
                        >
                          <h5 class="white--text fs-16">
                            {{ $t('Common.why_table_title') }}
                          </h5>
                        </th>
                      </tr>
                      <tr>
                        <th class="text-left font-weight-bold body-2">
                          {{ $t('Common.platform') }}
                        </th>
                        <th class="text-left font-weight-bold body-2">
                          {{ $t('Common.takein') }}
                        </th>
                        <th class="text-left font-weight-bold body-2">
                          {{ $t('Common.other_apps') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Sales</td>
                        <td>$1,000</td>
                        <td>$1,000</td>
                      </tr>
                      <tr>
                        <td>{{ $t('Common.commission') }}</td>
                        <td>$0</td>
                        <td class="red--text">-$300</td>
                      </tr>
                      <tr>
                        <td>{{ $t('Common.gross_profit') }}</td>
                        <td>$1,000</td>
                        <td>$700</td>
                      </tr>
                      <tr>
                        <td>{{ $t('Common.why_table_tips') }}</td>
                        <td>$150</td>
                        <td>$0</td>
                      </tr>
                      <tr>
                        <td>{{ $t('Common.why_table_total') }}</td>
                        <td>$1,150</td>
                        <td class="red--text">$700</td>
                      </tr>
                      <tr>
                        <td>{{ $t('Common.why_table_increase_d') }}</td>
                        <td class="green--text font-weight-bold">$450</td>
                        <td>none</td>
                      </tr>
                      <tr>
                        <td>{{ $t('Common.why_table_increase_p') }}</td>
                        <td class="green--text font-weight-bold">64%</td>
                        <td>none</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="start" justify="start">
      <v-col cols="12" md="6">
        <v-card :height="card_height">
          <v-card-title>
            <h1 class="font-weight-medium">
              {{ $t('Common.takein_free_marketing') }}
            </h1>
          </v-card-title>

          <v-card-text mb-5>
            <h3 class="font-weight-light">
              <span
                v-html="$t('Common.takein_free_marketing_description')"
              ></span>
            </h3>
          </v-card-text>

          <v-footer
            height="auto"
            absolute
            class="white pa-2 app--footer no-print"
          >
            <v-img
              src="https://storage.googleapis.com/download.takein.com/apps/images/460537.jpeg"
              height="240"
            ></v-img>
          </v-footer>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card :height="card_height">
          <v-card-title>
            <h1 class="font-weight-medium">
              {{ $t('Common.access_tools_to_grow') }}
            </h1>
          </v-card-title>

          <v-card-text>
            <h3 class="font-weight-light">
              <span
                v-html="$t('Common.access_tools_to_grow_description')"
              ></span>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" small>
                    <v-icon small>help</v-icon>
                  </v-btn>
                </template>
                <span
                  class="yellow--text dark"
                  v-html="$t('Common.last_call_tooltip')"
                  >.</span
                >
              </v-tooltip>
              <span
                v-html="$t('Common.access_tools_to_grow_description_2')"
              ></span>
            </h3>
          </v-card-text>
          <v-footer
            height="auto"
            absolute
            class="white pa-2 app--footer no-print"
          >
            <v-img
              src="https://storage.googleapis.com/download.takein.com/apps/images/381267.jpg"
              height="244"
            ></v-img>
          </v-footer>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="8">
        <v-card>
          <v-card-text align="center">
            <router-link to="/signup">
              <h1 class="display-1">{{ $t('Common.say_yes') }}</h1>
            </router-link>
          </v-card-text>
          <v-card-text align="center">
            <v-btn to="/signup" large class="success">
              {{ $t('Common.signup') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  metaInfo() {
    return {
      title: this.title,
      bodyAttrs: {
        class: 'app-1'
      },
      meta: [
        { name: 'og:title', content: this.title },
        { name: 'description', content: this.description, vmid: 'takein' },
        { name: 'og:description', content: this.description }
      ],
      script: [
        { innerHTML: 'var appId=1.1', body: true },
        { innerHTML: 'var appId=1.2', vmid: 'takein' }
      ]
    }
  },

  data() {
    return {
      card_height: 500,
      title:
        'Why Use TakeIn: Food App Increase your profit, Tips go to you and free marketing tool for food business.',
      description:
        'You make more money on TakeIn because it’s Zero percent commissions, no fees, and tips go to you. Tips go to restaurant. 0% Commissions, No Fees, 100% Free.'
    }
  },
  mounted() {
    this.$gtm.trackEvent({
      event: 'takein_event', // Event type [default = 'interaction'] (Optional)
      category: 'lead',
      action: 'lead_whytakein',
      label: 'lead_whytakein',
      method: 'lead_whytakein',
      payload: { method: 'lead_whytakein' }
    })
  },
  computed: {
    ...mapGetters({
      language: 'lang'
    })
  },
  watch: {
    language: function(newVal, oldVal) {
      this.$forceUpdate()
    }
  }
}
</script>
<style scoped>
.takein_green {
  color: #1bb535;
}
.theader {
  height: 24px;
  justify-content: center !important;
  align-items: center !important;
}
</style>
